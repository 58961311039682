$navbar-background-color: #252424;
$navbar-hover-color: #3f3939;
$navbar-hover-color2: #75da23;
$lightmode-background-color: #fcf9f9;
$lightmode-text-color: #252424;
$footer-link-text-color: #948f8f;

$background-color: #ffffff;
$navbar-font-size: 1.2em;
$transition-hover: 0.3s;
$font-1: "Montserrat";
$backup-font: "sans-serif";

$breakpoint-tablet: 768px;
