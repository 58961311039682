@import "_variables.scss";

#skillsContent {
	display: grid;
	column-gap: 20px;
	row-gap: 20px;
	align-self: center;
	justify-self: center;
	grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	div {
		align-self: center;
	}
	.skillsCard {
		height: fit-content;
		padding: 25px;
		min-height: 500px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		.imageDiv {
			display: grid;
			justify-self: center;
			align-self: center;
			min-height: 300px;
			@media screen and (min-width: 1340px) {
				min-height: 500px;
			}
		}
		img {
			width: 100%;
			height: auto;
			justify-self: center;
			align-self: center;
		}
		b {
			margin: 20px;
			text-align: center;
		}
		@media screen and (max-width: $breakpoint-tablet) {
			min-height: 400px;
		}
	}
	@media screen and (max-width: $breakpoint-tablet) {
		grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
	@media screen and (max-width: 425px) {
		grid-template-rows: repeat(auto-fill, minmax(150px, 1fr));
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}
}
