@import "_variables.scss";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	font-family: $font-1, $backup-font;
}

body {
	background-color: $lightmode-background-color;
}

#appContainer {
	height: fit-content;
}

.nextSectionLink {
	text-decoration: none;
	color: $navbar-background-color;
	border: 2px solid $navbar-background-color;
	border-radius: 50%;
	padding: 5px 6px 5px 6px;

	transition: $transition-hover;
	&:hover {
		background-color: $navbar-background-color;
		color: $lightmode-background-color;
	}
}

#homeSectionLinkDiv {
	display: flex;
	justify-content: space-evenly;
	height: 100%;
	margin-top: 70px;
	@media screen and (max-width: 800px) {
		margin-top: 15px;
	}
	@media screen and (max-width: 425px) {
		margin: 0 0 10px 0;
	}
}

#skillsSectionLink {
	position: absolute;
	top: 93%;
	left: 49%;
}

#content {
	padding: 0 15% 0 15%;

	#homeContent {
		padding-top: 150px;

		h1 {
			font-size: 4em;
			margin: 10px 0 10px 0;
		}
		h3 {
			font-size: 1.5em;
			margin: 15px 0 15px 0;
		}

		img {
			height: 30px;
			width: 30px;
			margin-right: 15px;
		}

		@media screen and (max-width: 425px) {
			padding-top: 50px;
		}
	}

	section {
		min-height: 90vh;
		height: fit-content;
		padding-top: 110px;
		h2 {
			font-size: 4em;
			margin-bottom: 25px;
			border-bottom: 3px solid $navbar-background-color;
		}
		#devTitle {
			font-size: 1.5em;
			margin: 15px 0 15px 0;
			border-bottom: none;
		}

		@media screen and (max-width: 425px) {
			padding-top: 50px;
		}
	}
}

#navbarContainer {
	background: $navbar-background-color;
	width: 100%;
	height: fit-content;
	min-height: 60px;
	color: $lightmode-background-color;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	padding: 0 15% 0 15%;

	a {
		text-decoration: none;
		color: $lightmode-background-color;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $transition-hover;
		padding: 5px 0 5px 0;
		&:hover {
			cursor: pointer;
		}
	}

	#logo {
    	max-width: 200px; 
    	max-height: 200px;
		position: absolute;
		//left: 200px;
		
	}
	 #logoAndToggleDiv {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 60px;
		width: 100%;
	} 
}

.navbarToggle {
	cursor: pointer;
	font-size: 24px;

	#fontAwesomeIcon {
		color: $lightmode-background-color;
	}
}

.mainNavbar {
	list-style-type: none;
	display: none;
	height: 100%;
	background-color: $navbar-background-color;
	li {
		text-align: center;
		margin: 0 15px 0 15px;
		padding-bottom: 5px;
		border-bottom: 2px solid $navbar-background-color;

		&:hover {
			border-bottom: 2px solid $lightmode-background-color;
		}
	}
}

.active {
	display: block;
}

@media screen and (min-width: $breakpoint-tablet) {
	#navbarContainer {
		display: flex;
		justify-content: space-between;
		padding-bottom: 0;
		height: 100px;
		align-items: center;
		padding: 0 15% 0 15%;
		li {
			text-align: center;
		}
		.navbarToggle {
			display: none;
		}
	}
	
	

	.mainNavbar {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding-bottom: 0;

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 15px;
			transition: $transition-hover;
			height: fit-content;
		}
		li:hover {
			cursor: pointer;
		}
	}
}

@media screen and (max-width: $breakpoint-tablet) {
	#content {
		padding: 0 10% 0 10%;
	}
	#navbarContainer {
		padding: 0 10% 0 10%;
	}
	#logo {
		display: none;
	}
	#backgroundImage {
		display: none;
	}
}

@media screen and (max-width: 618px) {
	#content {
		padding: 0 5% 0 5%;
	}
	#navbarContainer {
		padding: 0 5% 0 5%;
	}
}

@media screen and (max-width: 425px) {
	#content {
		padding: 0 3% 0 3%;
	}
	#navbarContainer {
		padding: 0 4% 0 4%;
	}
}

@media screen and (max-width: 550px) {
	.techStackList {
		flex-direction: column;
		align-items: center;
	}
	.projectCard {
		h3 {
			margin-bottom: 15px;
		}
		p {
			min-height: 100px;
		}
	}
}
