@import "_variables.scss";

#projectsContent {
	display: grid;
	column-gap: 30px;
	row-gap: 30px;
	align-self: center;
	justify-self: center;
	grid-template-rows: repeat(auto-fill, minmax(500px, 1fr));
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	.projectCard {
		background-color: white;
		padding: 25px;
		border: 1px solid $navbar-background-color;
	}
	img {
		width: 100%;
		height: auto;
	}
	h3 {
		text-align: center;
		margin-bottom: 25px;
		@media screen and (max-width: 618px) {
			margin-bottom: 15px;
		}
	}
	p {
		margin: 15px 0 5px 0;
		min-height: 130px;
		@media screen and (max-width: 618px) {
			font-size: 0.8em;
			min-height: 100px;
		}
	}
	.buttonDiv {
		display: flex;
		justify-content: space-around;
	}
	button {
		border: 2px solid $navbar-background-color;
		background-color: white;
		color: black;
		height: 3em;
		width: 7vw;
		min-width: 110px;
		padding: 5px;
		transition: $transition-hover;
	}
	button:hover {
		cursor: pointer;
		background-color: $navbar-background-color;
		color: white;
	}

	@media screen and (max-width: 618px) {
		grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}
}

.techStackList {
	display: flex;
	list-style: none;
	justify-content: space-evenly;
	margin-bottom: 25px;
}