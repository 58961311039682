@import "_variables.scss";

#contactDiv {
	display: flex;
	#linksDiv {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		width: 50%;
		img {
			height: 50px;
			width: 50px;
		}
		@media screen and (max-width: 800px) {
			width: 100%;
			display: flex;
			margin: 25px 0 25px 0;
		}
	}
}

#contactForm {
	height: fit-content;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	label {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;
		width: 100%;
		margin: 0px 0 15px 0;
		input {
			width: 100%;
			line-height: 1.5em;
			padding: 5px;
			margin-top: 10px;
			border: 1px solid $navbar-background-color;
		}
	}
	textarea {
		max-width: 100%;
		width: 100%;
		height: fit-content;
		min-height: 200px;
		resize: none;
		padding: 5px;
		margin-top: 10px;
		border: 1px solid $navbar-background-color;
	}
	button {
		width: 100%;
		height: 34px;
		background-color: $navbar-background-color;
		color: white;
		border: none;
		&:hover {
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 800px) {
	#contactDiv {
		flex-direction: column;
	}
	#contactForm {
		width: 100%;
	}
}