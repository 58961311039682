@import "_variables.scss";

#Home {
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 425px) {
		flex-direction: column;
		justify-content: space-evenly;
	}
}

#bgImageDiv {
	height: 600px;
	width: 600px;
	display: flex;
	align-items: center;

	#backgroundImage {
		height: auto;
		width: 100%;
	}
	@media screen and (max-width: 425px) {
		height: auto;
		width: auto;
	}
}